import "./plugins";
import "./components/design";

import Vue from "vue";
import Vuetify from "vuetify";
import interceptors from "./services/interceptors";
import store from "./store";
import router from "./router";
import i18n from "./plugins/i18n";
import App from "./App.vue";

import * as VueGoogleMaps from "vue2-google-maps";

interceptors.setupInterceptors();

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
  load: {
    region: "IT",
    language: "it",
    key: "AIzaSyBdbF9v3ULta6r0yHzK-skQICNm7ddTPCA",
    libraries: "places", // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },

  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,

  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then set installComponents to 'false'.
  //// If you want to automatically install all the components this property must be set to 'true':
  installComponents: true,
});

const vuetify = new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },

  theme: {
    themes: {
      light: {
        primary: "#885b31",
        secondary: "#424242",
        accent: "#593c20",
        error: "#c62d2d",
        info: "#ffa551",
        success: "#a7c62d",
        warning: "#f75d00",
      },
      dark: {
        primary: "#885b31",
        secondary: "#424242",
        accent: "#593c20",
        error: "#c62d2d",
        info: "#ffa551",
        success: "#a7c62d",
        warning: "#f75d00",
      },
    },
  },
});

new Vue({
  i18n,
  store,
  router,
  vuetify: vuetify,
  render: (h) => h(App),
  onIdle() {
    if (store.state.user.id && store.state.user.idlekick != 0) {
      // We are logged
      store
        .dispatch("logout")
        .then(() => {
          router.push("/login");
        })
        .catch(() => {
          router.push("/login");
        });
    }
  },
}).$mount("#app");
