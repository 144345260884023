<template>
  <v-app-bar dark app :src="background_image">
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        gradient="to top right, rgba(10,10,10,.5), rgba(90,90,90,.8)"
      ></v-img>
    </template>

    <span class="yeast-logo-span"
      ><img class="forno-logo" src="../assets/forno_logo_bianco.png"
    /></span>

    <span class="header-copyright">{{
      $t("yeast-copy", { version: packageInfo.version })
    }}</span>

    <div class="mx-3" />
  </v-app-bar>
</template>

<style>
.appbar {
  .v-toolbar__content {
    height: 120px;
  }
}
</style>

<style scoped>
.yeast-logo-span {
  margin-right: 10px;
}

.yeast-logo {
  max-height: 50px;
  margin-left: 5px;
  margin-right: 5px;
}

.forno-logo {
  max-height: 50px;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 5px;
}

.header-copyright {
  text-decoration: none;
  text-transform: uppercase;
}

.header-welcome {
  text-decoration: none;
  text-transform: uppercase;
}

@media screen and (max-width: 800px) {
  .v-app-bar {
    font-size: 12px;
  }

  .forno-logo {
    max-height: 30px;
  }
  .header-copyright {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .v-app-bar {
    font-size: 12px;
  }

  .header-welcome {
    display: none;
  }

  .header-copyright {
    display: none;
  }
}
</style>

<script>
const packageInfo = require("../../package.json");

export default {
  mounted() {
    this.profile = [
      { title: this.$t("header-profile"), url: "/profile" },
      { divider: true },
      { title: this.$t("header-logout"), func: this.logout },
    ];
  },

  data: () => ({
    packageInfo: packageInfo,
    background_image: require("../assets/header.jpg"),
  }),

  methods: {},
};
</script>
0
