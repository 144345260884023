import store from "../store";
import config from "../config";
import axios from "axios";

let timer = null;

function checkSpooler() {
  timer = null;
  if (store.state.user.id) {
    let url = "http://localhost:" + config.printerSpoolLocalPort + "/ping";
    axios
      .post(url)
      .then(() => {
        store.dispatch("spoolerOnline");
        setupSpoolerTimeout();
      })
      .catch(() => {
        //console.log(err);
        store.dispatch("spoolerOffline");
        setupSpoolerTimeout();
      });
  } else {
    setupSpoolerTimeout();
  }
}

function setupSpoolerTimeout() {
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => {
    checkSpooler();
  }, config.printerSpoolLocalPort);
}

checkSpooler();
