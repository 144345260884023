<template>
  <v-footer class="core-footer">
    <v-container>
      <v-row align="center">
        <v-col col="12" class="mr-0 font-weight-light pt-md-0 text-center">
          &copy; 2019 - {{ new Date().getFullYear() }}, {{ $t("footer-title") }}
     
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "CoreFooter",
};
</script>

<style scoped>
.core-footer {
    font-size: 16px;
    font-weight : 500;
    text-decoration: none;
    text-transform: uppercase;
    height: 80px;
    }

.footer-heart {
  color: red;
}
</style>
