import {
  required,
  required_if,
  email,
  confirmed,
  min_value,
  max_value,
  regex,
  numeric,
} from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import i18n from "./i18n";

extend('required_vat', value => {
  if (value.length == 11) {
    return true;
  }

  return i18n.t("vee-required-vat");
});

extend('required_fc', value => {
  if (value.length == 16) {
    return true;
  }

  return i18n.t("vee-required-fc");
});

extend('required_fc_business', value => {
  if (value.length == 11 || value.length == 16) {
    return true;
  }

  return i18n.t("vee-required-fc");
});


extend("required_name_or_business", {
  ...required,
  message: i18n.t("vee-required-name-or-business"),
});

extend("required_if", {
  ...required_if,
  message: i18n.t("vee-required"),
});

extend("required", {
  ...required,
  message: i18n.t("vee-required"),
});

extend("email", {
  ...email,
  message: i18n.t("vee-email"),
});

extend("confirmed", {
  ...confirmed,
  message: i18n.t("vee-confirmed"),
});

extend("min_value", {
  ...min_value,
  message: i18n.t('vee-min_value')
});

extend("max_value", {
  ...max_value,
  message: i18n.t("vee-max_value"),
});

extend("regex", {
  ...regex,
  message: i18n.t("vee-regex"),
});

extend("numeric", {
  ...numeric,
  message: i18n.t("vee-numeric"),
});

extend("decimal", {
  validate: (value, { decimals = "*", separator = "." } = {}) => {
    if (i18n.locale == "it") separator = ",";
    if (value === null || value === undefined || value === "") {
      return {
        valid: false,
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
    );
    return {
      valid: regex.test(value),
      data: {
        serverMessage: "Only decimal values are available",
      },
    };
  },
  message: i18n.t("vee-decimal"),
});
