<template>
  <v-container justify-center id="login" class="fill-height" tag="section">
    <v-row justify="center">
      <v-slide-y-transition appear>
        <base-material-card
          color="primary"
          light
          max-width="100%"
          width="400"
          class="px-5 py-3"
        >
          <template v-slot:heading>
            <div class="text-center">
              <v-icon>mdi-lock</v-icon>
              {{ $t("login-title") }}
            </div>
          </template>
          <v-card-text class="text-center">
            <div class="container-logo-small-pages">
              <img src="../assets/logo.png" alt="Forno Roscioli Logo" />
            </div>
            <ValidationObserver ref="obs" v-slot="{ invalid, validated }">
              <v-form @submit="login()">
                <ValidationProvider
                  rules="required|email"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    color="secondary"
                    v-model="email"
                    prepend-icon="mdi-email"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </ValidationProvider>

                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <v-text-field
                    autocomplete
                    class="mb-8"
                    color="secondary"
                    v-model="password"
                    prepend-icon="mdi-lock-outline"
                    :append-icon="password_view ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="() => (password_view = !password_view)"
                    :type="password_view ? 'password' : 'text'"
                    :error-messages="errors"
                    :success="valid"
                    required
                  />
                </ValidationProvider>

                <v-btn
                  type="submit"
                  @click="login()"
                  v-if="!logging"
                  color="primary"
                  >{{ $t("login-button") }}</v-btn
                >
              </v-form>
            </ValidationObserver>
            <v-progress-circular
              v-if="logging"
              :indeterminate="true"
              :rotate="0"
              :size="32"
              :width="4"
              color="primary"
            ></v-progress-circular>

            <qrcode-stream
              :camera="camera"
              :style="getQrCodeStyle"
              @decode="onDecodeQrCode"
              @init="onInitQrCode"
            ></qrcode-stream>

            <div v-if="qrCodeInitInProgress">
              <p>{{ $t("login-init-qr-code-camera") }}</p>
              <v-progress-circular
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="secondary"
              ></v-progress-circular>
            </div>

            <div v-if="cameraError">
              <div style="height:10px"></div>
              <v-alert type="error" dense dark dismissible>
                <span class="text-uppercase" v-text="cameraError" />
              </v-alert>
            </div>

            <div v-if="loginError">
              <div style="height:10px"></div>
              <v-alert type="error" dense dark dismissible>
                <span class="text-uppercase" v-text="loginError" />
              </v-alert>
            </div>
            <p style="margin-top:10px;">
              <router-link to="/recovery">{{
                $t("login-lost-password")
              }}</router-link>
            </p>
            <p style="margin-top:10px;">
              {{ packageInfo.version }}
            </p>
          </v-card-text>
        </base-material-card>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
const packageInfo = require("../../package.json");
import { QrcodeStream } from "vue-qrcode-reader";

export default {
  data() {
    return {
      logging: false,
      email: "",
      password: "",
      loginError: "",
      password_view: String,
      qrCodeInitInProgress: true,
      cameraError: null,
      camera: "auto",
      packageInfo: packageInfo
    };
  },

  mounted() {
    this.logging = false;
    this.$refs.obs.reset();
  },

  computed: {
    getQrCodeStyle() {
      if (this.qrCodeInitInProgress)
        return "margin: auto; margin-top:10px; width:0px; height:0px;";
      else return "margin: auto; margin-top:10px; width:100px; height:100px;";
    }
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    QrcodeStream
  },

  methods: {
    async onInitQrCode(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.cameraError =
            "ERROR: you need to grant camera access permission";
        } else if (error.name === "NotFoundError") {
          //this.cameraError = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.cameraError =
            "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.cameraError = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.cameraError = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.cameraError =
            "ERROR: Stream API is not supported in this browser";
        } else if (error.name === "InsecureContextError") {
          this.cameraError =
            "ERROR: Camera access is only permitted in secure context. Use HTTPS or localhost rather than HTTP.";
        } else {
          this.cameraError = `ERROR: Camera error (${error.name})`;
        }
      } finally {
        this.qrCodeInitInProgress = false;
      }
    },

    async onDecodeQrCode(qrcode) {
      this.pause();
      await this.timeout(500);
      this.unpause();

      this.logging = true;
      this.$store
        .dispatch("login", { qrcode })
        .then(() => {
          this.$store
            .dispatch("fetchStaticData")
            .then(() => {
              this.$router.push("/");
            })
            .catch(err => {
              this.loginError = err;
              this.logging = false;
            });
        })
        .catch(err => {
          this.loginError = err;
          this.logging = false;
        });
    },

    unpause() {
      this.camera = "auto";
    },

    pause() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms);
      });
    },

    login: function() {
      let email = this.email;
      let password = this.password;
      this.loginError = "";
      this.logging = true;
      this.$store
        .dispatch("login", { email, password })
        .then(() => {
          this.$store
            .dispatch("fetchStaticData")
            .then(() => {
              this.$router.push("/");
            })
            .catch(err => {
              this.loginError = err;
              this.logging = false;
            });
        })
        .catch(err => {
          this.loginError = err;
          this.logging = false;
        });
    }
  }
};
</script>
